import React, { useState, useEffect } from "react";
import "./nfc.css";
import nfc from "../../images/nfc.png";
import logo from "../../images/logo.png";
import up from "../../images/up.png";
import dp from "../../images/dp.png";
import bt1 from "../../images/bt1.png";
import bt2 from "../../images/bt2.png";
import Insta from "../../images/insta.png";
import Twitter from "../../images/twitter.png";
import Facebook from "../../images/facebook.svg";
import LinkedIn from "../../images/linkedIn.svg";
import WhatsAppImg from "../../images/whatsapp.png";
import OpenSea from "../../images/opensea.png";
import Discord from "../../images/discord.png";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import cloudUpload from './cloudUpload.svg'
import eye from './eye.svg'
import copySVG from './copySVG.svg'
import { Tooltip } from '@mui/material'

const NFC = (props) => {
  const [theme, settheme] = React.useState('DarkMode');
  const [disabled, setdisabled] = useState(false);
  const [editDisabled, seteditDisabled] = useState(false);

  const handleChange = (event) => {
    settheme(event.target.value);
  };

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};
  const [active, setActive] = useState(1);
  const [error, seterror] = useState(false);
  const [snackbar, setsnackbar] = useState(false);
  const [success, setsuccess] = useState(true);
  const [name, setName] = useState(props?.userInfo?.name ? props?.userInfo?.name : "");
  const [title, setTitle] = useState(props.userInfo.title ? props.userInfo.title : "");
  const [bio, setBio] = useState(props.userInfo.bio ? props.userInfo.bio : "");
  const [number, setNumber] = useState(props.userInfo.number ? props.userInfo.number : "");
  const [mail, setMail] = useState(props.userInfo.mail ? props.userInfo.mail : "");
  const [uniqueUrl, setuniqueUrl] = useState(props.userInfo.uniqueUrl ? props.userInfo.uniqueUrl : props.userInfo.username);
  const [profileImage, setprofileImage] = useState(props.userInfo.profileImage ? props.userInfo.profileImage : "");
  const [bannerImage, setbannerImage] = useState(props.userInfo.bannerImage ? props.userInfo.bannerImage : "");
  const [instaurl, setInstaUrl] = useState(props.userInfo.instagramUrl ? (props.userInfo.instagramUrl.split('/').length > 1 ? props.userInfo.instagramUrl.split('/')[props.userInfo.instagramUrl.split('/').length - 1] : props.userInfo.instagramUrl) : "");
  const [facebookurl, setFacebookUrl] = useState(props.userInfo.facebookUrl);
  const [linkedinurl, setLinkedinUrl] = useState(props.userInfo.linkedinUrl);
  const [twitterurl, setTwitterUrl] = useState(props.userInfo.twitterUrl ? (props.userInfo.twitterUrl.split('/').length > 1 ? props.userInfo.twitterUrl.split('/')[props.userInfo.twitterUrl.split('/').length - 1] : props.userInfo.twitterUrl) : "");
  const [WhatsApp, setWhatsApp] = useState(props.userInfo.whatsapp ? props.userInfo.whatsapp : "");
  const [openSeaurl, setOpenSeaUrl] = useState(props.userInfo.openseaUrl ? (props.userInfo.openseaUrl.split('/').length > 1 ? props.userInfo.openseaUrl.split('/')[props.userInfo.openseaUrl.split('/').length - 1] : props.userInfo.openseaUrl) : "");
  const [discordurl, setDiscordUrl] = useState(props.userInfo.discordUrl ? props.userInfo.discordUrl : "");
  const [copyText, setCopyText] = useState('Copy URL')

  const handelClick1 = async (e) => {
    e.preventDefault();
    seterror(false)
    seteditDisabled(false)
    if (disabled) {
      return;
    }
    setdisabled(true)
    PostData();
  };
    
  const config = {
    headers: {
        authorization: `Bearer ${props.accessToken}`,
    },
  }

  useEffect(() => {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/blvck-nfc/getUser`, config).then((res) => {
        if (res.data.msg == "User doesn't exists") {
          window.location.reload()
        }
        props.setuserInfo(res.data.userInfo)
        localStorage.setItem('userInfo', JSON.stringify(res.data.userInfo))
      })
  }, [])

  const PostData = async () => {
    const formData = new FormData()
    formData.append('file', profileImage)
    formData.append('upload_preset', 'FoxLedgerStudio-BLVCK')

    axios.post(
        'https://api.cloudinary.com/v1_1/foxledgerstudio/image/upload/',
        formData
    )
    .then(async (res) => {
      const userResponse = {
        name: name,
        number: number,
        mail: mail,
        bio: bio,
        title: title,
        profileImage: res.data.url,
        uniqueUrl: uniqueUrl,
      };
      let response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/blvck-nfc/update-profile`,
        userResponse, config
      );
      const { data } = response;
      if (data.msg == "Url already in use") {
        // alert(data.msg);
        seteditDisabled(true)
        seterror('Url already in use')
        setdisabled(false)
        return;
      }
      if (data.msg.includes("Profile saved successfully")) {
        // alert(data.msg);
        setdisabled(false)
        setsuccess("Profile saved successfully, please don't forget to activate your card again as you just changed your unique link")
        setsnackbar(true)
        return;
      }
      if (data.msg == "Profile Updated!") {
        // alert(data.msg);
        setdisabled(false)
        setsuccess("Profile updated")
        setsnackbar(true)
        return;
      }
    }).catch((error) => {
      console.log(error)
      setdisabled(false)
    })
  };
  const handelClick2 = async (e) => {
    seteditDisabled(false)
    if (disabled) {
      return;
    }
    e.preventDefault();
    setdisabled(true)
    PostData2();
  };

  const PostData2 = async () => {
    const userResponse = {
      instagramUrl: instaurl ? `https://instagram.com/${instaurl}` : instaurl,
      facebookUrl: facebookurl,
      linkedinUrl: linkedinurl,
      twitterUrl: twitterurl ? `https://twitter.com/${twitterurl}` : twitterurl,
      openseaUrl: openSeaurl ? `https://opensea.io/${openSeaurl}` : openSeaurl,
      discordUrl: discordurl,
      whatsapp: WhatsApp,
    };
    let response2 = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/blvck-nfc/update-social-links`,
      userResponse,
      config
    );
    const { data } = response2;
    if (data.msg == 'Social Links Updated!') {
      // alert(data.msg);
      setdisabled(false)
      setsuccess("Profile updated")
      setsnackbar(true)
      return;
    } else {
      setdisabled(false)
    }
  };
  const handelClick3 = async (e) => {
    seteditDisabled(false)
    if (disabled) {
      return;
    }
    e.preventDefault();
    setdisabled(true)
    PostData3();
  };
  const PostData3 = async () => {
    const formData = new FormData()
    formData.append('file', bannerImage)
    formData.append('upload_preset', 'FoxLedgerStudio-BLVCK')

    axios.post(
        'https://api.cloudinary.com/v1_1/foxledgerstudio/image/upload/',
        formData
    )
    .then(async (res) => {
      const userResponse = {
        bannerImage: res.data.url,
        theme: theme
      };
      let response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/blvck-nfc/update-profile`,
        userResponse, config
      );
      const { data } = response;
      if (data.msg == "Profile Updated!") {
        // alert(data.msg);
        setsuccess("Profile updated")
        setsnackbar(true)
        setdisabled(false)
        return;
      }
      setdisabled(false)
    }).catch((error) => {
      console.log(error)
      setdisabled(false)
    })
  };

  
const Alert = React.forwardRef(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


  return (
    <div className="nfc-page">
      <Snackbar open={snackbar} autoHideDuration={6000} onClose={() => setsnackbar(false)} vertical={'bottom'} horizontal={'right'} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
      <Alert onClose={() => setsnackbar(false)} severity="success" sx={{ width: '100%' }}>
          {success}
        </Alert>
      </Snackbar>
      <div style={{ position: 'relative' }} className="nfc-content">
        <div className="nfc-banner">
          <a target="_blank" href={`${process.env.REACT_APP_FRONTEND_URL || 'https://nfc.blvck.com'}/profile/${uniqueUrl}`}>
          <button className="previewButtonDisplay" style={{
                borderRadius: '20px',
                position: 'absolute',
                top: '15px',
                right: '15px',
                display: 'flex',
                alignItems: 'center',
                gap: '6px',
                fontWeight: 500,
                cursor: 'pointer',
                fontFamily: '"Montserrat", sans-serif',
                fontSize: '15px',
                background: 'black',
                color: 'white',
                border: '2px solid white',
                padding: '6px 15px',
          }}><img style={{ width: '22px' }} src={eye} /> Preview</button></a>
          <img src={logo} alt="BLVCK" className="nfc-banner-img" />
          <div className="nfc-nav">
            <div
              className={active == 1 ? "nfc-nav-active" : "nfc-nav-passive"}
              onClick={() => setActive(1)}
            >
              NFC Profile
            </div>
            <div
              className={active == 2 ? "nfc-nav-active" : "nfc-nav-passive"}
              onClick={() => setActive(2)}
            >
              Socials
            </div>
            <div
              className={active == 3 ? "nfc-nav-active" : "nfc-nav-passive"}
              onClick={() => setActive(3)}
            >
              Customization
            </div>
            <div
              className={active == 4 ? "nfc-nav-active" : "nfc-nav-passive"}
              onClick={() => setActive(4)}
            >
              Settings
            </div>
          </div>
        </div>
        <div className="nfc-con">
          {active == 1 ? (
            <div style={{ gap: '10px' }} className="nfc-con-flex">
              <h1 className="nfc-con-vert-flex-a-header">your unique URL</h1>
              <div className="nfc-con-flex-link">
                <span className="nfc-con-flex-link-txt nfc-con-flex-link-txt-dis">
                  https://nfc.blvck.com/profile/
                </span>
                <span style={error ? { borderBottom: 'rgb(255, 0, 0) 2px solid', paddingBottom: '10px', display: 'inline-flex', gap: '10px' } : { borderBottom: 'rgb(217, 217, 217) 2px solid', paddingBottom: '10px', display: 'inline-flex', gap: '10px' }}>
                  <input
                    className="nfc-con-flex-link-txt"
                    placeholder="564dwq631sa5s10"
                    value={uniqueUrl}
                    style={error ? { color: 'rgb(255, 0, 0)' } : {}}
                    disabled={!editDisabled}
                    onChange={(e) => setuniqueUrl(e.target.value.replace(/ /g, '-'))}
                  />
                  <Tooltip followCursor={true} title={copyText}>
                  <img style={{ marginTop: '-1px', cursor: 'pointer' }} onClick={() => {
                                            navigator.clipboard.writeText(
                                                `https://nfc.blvck.com/profile/${uniqueUrl}`
                                            )

                                            navigator.clipboard
                                                .readText()
                                                .then((clipText) => {
                                                    // console.log(clipText)
                                                    setCopyText('Copied')
                                                    setTimeout(() => {
                                                        setCopyText('Copy URL')
                                                    }, 3000)
                                                })
                                        }} src={copySVG} />
                </Tooltip>
                  <span 
                  onClick={() => seteditDisabled(true)}
                  style={editDisabled ? { background: 'grey', cursor: 'not-allowed', marginLeft: '8px' } : { marginLeft: '8px' }} className="nfc-con-flex-link-txt-button">Edit</span>
                </span>
              {editDisabled && <p style={error && error.includes('Url already in use') ? {     fontFamily: '"Montserrat", sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '15px',
    position: 'absolute',
    color: 'rgb(255, 0, 0)',
    marginTop: '10px' } : { fontFamily: '"Montserrat", sans-serif',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '15px',
    position: 'absolute',
    color: '#bd5151',
    marginTop: '10px' }}>{error && error.includes('Url already in use') ? 'URL already in use, please choose another' : 'If you edit your profile link, please activate your card again'}</p>}
              </div>
              <div style={editDisabled ? { marginTop: '40px' } : { marginTop: '30px' }}  className="nfc-con-vert-flex">
                <div className="nfc-con-vert-flex-a">
                  <div className="nfc-con-vert-flex-a-header">profile</div>
                  <div className="nfc-con-vert-flex-a-img-upload">
                    <img
                    style={{ height: '66px', width: '66px', borderRadius: '100%', objectFit: 'cover' }}
                      src={
                        typeof profileImage === 'object'
                            ? URL.createObjectURL(
                                  profileImage
                              )
                            : (profileImage ? profileImage : up)
                    }
                      alt="Upload"
                      className="nfc-con-vert-flex-a-img"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      className="nfc-con-vert-flex-a-img-upload-txt"
                      placeholder="Add a profile photo"
                      onChange={(e) =>
                        setprofileImage(e.target.files[0])
                      }
                    />
                    <label className="imageLabel">Add a profile photo</label>
                  </div>
                  <input
                    type="text"
                    placeholder="Name"
                    className="nfc-con-vert-flex-a-input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Title"
                    className="nfc-con-vert-flex-a-input"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Bio"
                    className="nfc-con-vert-flex-a-input"
                    value={bio}
                    onChange={(e) => setBio(e.target.value)}
                  />
                </div>
                <div className="nfc-con-vert-flex-a">
                  <div className="nfc-con-vert-flex-a-header">
                    contact Details
                  </div>
                  <input
                    type="text"
                    placeholder="Mail"
                    className="nfc-con-vert-flex-a-input"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                  />
                  <input
                    type="Number"
                    placeholder="Number"
                    className="nfc-con-vert-flex-a-input"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {active == 2 ? (
            <div className="nfc-con-flex-2">
              <h1 className="nfc-con-vert-flex-a-header">Social Links</h1>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div style={{     display: 'flex',
    gap: '14px',
    alignItems: 'center',
    borderBottom: '1px solid #cccccc',
    width: 'max-content', }}>
                <img                   style={{ width: '22px' }}
 src={Insta} alt="Insta" className="profile-social" />
                <input
                  style={{ borderBottom: 'none' }}
                  placeholder="Instagram username"
                  className="nfc-con-vert-flex-a-input"
                  value={instaurl}
                  onChange={(e) => setInstaUrl(e.target.value.split('/').length > 1 ? e.target.value.split('/')[e.target.value.split('/').length - 1] : e.target.value)}
                />
              </div>
              <div style={{     display: 'flex',
    gap: '14px',
    alignItems: 'center',
    borderBottom: '1px solid #cccccc',
    width: 'max-content', }}>
                <img                   style={{ width: '22px' }}
 src={Facebook} alt="Facebook" className="profile-social" />
                <input
                  style={{ borderBottom: 'none' }}
                  placeholder="Facebook profile link"
                  className="nfc-con-vert-flex-a-input"
                  value={facebookurl}
                  onChange={(e) => setFacebookUrl(e.target.value)}
                />
              </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              <div style={{     display: 'flex',
    gap: '9px',
    alignItems: 'center',
    borderBottom: '1px solid #cccccc',
    width: 'max-content', }}>
                <img                   style={{ width: '26px' }}
 src={Twitter} alt="Insta" className="profile-social" />
              <input
                style={{ borderBottom: 'none' }}
                placeholder="Twitter username"
                className="nfc-con-vert-flex-a-input"
                value={twitterurl}
                onChange={(e) => setTwitterUrl(e.target.value.split('/').length > 1 ? e.target.value.split('/')[e.target.value.split('/').length - 1] : e.target.value)}
              />
              </div>
              <div style={{     display: 'flex',
    gap: '14px',
    alignItems: 'center',
    borderBottom: '1px solid #cccccc',
    width: 'max-content', }}>
                <img                   style={{ width: '22px' }}
 src={LinkedIn} alt="LinkedIn" className="profile-social" />
                <input
                  style={{ borderBottom: 'none' }}
                  placeholder="LinkedIn profile link"
                  className="nfc-con-vert-flex-a-input"
                  value={linkedinurl}
                  onChange={(e) => setLinkedinUrl(e.target.value)}
                />
              </div>
              </div>
              <div style={{     display: 'flex',
    gap: '12px',
    alignItems: 'center',
    borderBottom: '1px solid #cccccc',
    width: 'max-content', }}>
                <img                   style={{ width: '22px' }}
 src={WhatsAppImg} alt="Insta" className="profile-social" />
              <input
              type="Number"
                                style={{ borderBottom: 'none' }}
                placeholder="WhatsApp phone number"
                className="nfc-con-vert-flex-a-input"
                value={WhatsApp}
                onChange={(e) => setWhatsApp(e.target.value)}
              /></div>
              <div style={{     display: 'flex',
    gap: '10px',
    alignItems: 'center',
    borderBottom: '1px solid #cccccc',
    width: 'max-content', }}>
                <img                   style={{ width: '24px' }}
 src={OpenSea} alt="Insta" className="profile-social" />
              <input
                                style={{ borderBottom: 'none' }}
                placeholder="Opensea username or wallet address"
                className="nfc-con-vert-flex-a-input"
                value={openSeaurl}
                onChange={(e) => setOpenSeaUrl(e.target.value.split('/').length > 1 ? e.target.value.split('/')[e.target.value.split('/').length - 1] : e.target.value)}
              />
              </div>
              <div style={{     display: 'flex',
    gap: '10px',
    alignItems: 'center',
    borderBottom: '1px solid #cccccc',
    width: 'max-content', }}>
                <img                   style={{ width: '24px' }}
 src={Discord} alt="Insta" className="profile-social" />
              <input
                                style={{ borderBottom: 'none' }}
                placeholder="Discord invitation link"
                className="nfc-con-vert-flex-a-input"
                value={discordurl}
                onChange={(e) => setDiscordUrl(e.target.value)}
              />
              </div>
            </div>
          ) : (
            ""
          )}
          {active == 3 ? (
            <div className="nfc-con-flex-2">
              <h1 className="nfc-con-vert-flex-a-header">Banner</h1>
              {/* <Carousel
  swipeable={false}
  draggable={false}
  showDots={true}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  // autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={1000}
  keyBoardControl={true}
  customTransition="all .5"
  transitionDuration={500}
  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  // deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
  <img src={'https://images.unsplash.com/photo-1550064824-8f993041ffd3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60'} style={{ width: "100%", height: "100%" }} />
  <img src={'https://images.unsplash.com/photo-1549833284-6a7df91c1f65?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60'} style={{ width: "100%", height: "100%" }} />
  <img src={'https://images.unsplash.com/photo-1549985908-597a09ef0a7c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60'} style={{ width: "100%", height: "100%" }} />
  <img src={'https://images.unsplash.com/photo-1550064824-8f993041ffd3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60'} style={{ width: "100%", height: "100%" }} />
</Carousel> */}
<div style={{ width: 'max-content', position: 'relative' }} className="nfc-con-vert-flex-a-img-upload">
                    <img
                    style={{ height: '120px', width: '200px', borderRadius: '35px', objectFit: 'cover' }}
                      src={
                        typeof bannerImage === 'object'
                            ? URL.createObjectURL(
                                  bannerImage
                              )
                            : (bannerImage ? bannerImage : up)
                    }
                      alt="Upload"
                      className="nfc-con-vert-flex-a-img"
                    />
                    <input
                    style={{ height: '120px', width: '200px', borderRadius: '35px', zIndex: 1 }}
                      type="file"
                      accept="image/*"
                      className="nfc-con-vert-flex-a-img-upload-txt"
                      placeholder="Add a profile photo"
                      onChange={(e) =>
                        setbannerImage(e.target.files[0])
                      }
                    />
                    <img style={{ position: 'absolute', width: '40px',
    left: '50%',
    transform: 'translateX(-50%)', cursor: 'pointer' }} src={cloudUpload} />
                  </div>
              <FormControl style={{ margin: 0, marginTop: '15px', width: 'max-content' }} variant="standard" sx={{ m: 1 }}>
                <InputLabel id="demo-simple-select-standard-label">Theme</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={theme}
                  onChange={handleChange}
                  label="Theme"
                >
                  <MenuItem value={'DarkMode'}>Dark Mode</MenuItem>
                  {/* <MenuItem value={'LightMode'}>Light Mode</MenuItem> */}
                </Select>
              </FormControl>
            </div>
          ) : (
            ""
          )}
          {active == 4 ? (
            <div className="nfc-con-flex-2">
              <h1 className="nfc-con-vert-flex-a-header">Settings</h1>
              <div onClick={() => {
                  localStorage.clear()
                  window.location.reload()
                }} className="nfc-con-vert-flex-a-input-2 hover">Logout</div>
              <div className="nfc-con-vert-flex-a-input-1 hover">
                <a style={{ color: '#7f7f80' }} target="_blank" href="https://blvck.com/pages/terms-of-service">Terms and Services</a>
              </div>
              <div className="nfc-con-vert-flex-a-input-1 hover">
              <a style={{ color: '#7f7f80' }} target="_blank" href="https://blvck.com/pages/nfcfaq">FAQs</a>
</div>
              <div className="nfc-con-vert-flex-a-input-1 hover"><a style={{ color: '#7f7f80' }} target="_blank" href="mailto:Support@blvck.com">Support</a></div>
            </div>
          ) : (
            ""
          )}
          {active !== 4 && <button
          style={disabled ? { background: 'grey', cursor: 'not-allowed', border: 'none' } : editDisabled ? { marginTop: '25px' } : {}} 
            className="nfc-con-btn"
            onClick={
              active == 1 ? (e) => handelClick1(e) : (active == 3 ? (e) => handelClick3(e) : (e) => handelClick2(e))
            }
          >
            {disabled ? 'Saving...' : 'Save'}
          </button>}
        </div>
      </div>
      <div style={{ width: '100px', background: '#1e1e1e', width: '500px', padding: '105px 80px 40px 80px' }} className="nfc-preview">
        <a style={{ position: 'absolute', top: '30px' }} target="_blank" href={`${process.env.REACT_APP_FRONTEND_URL || 'https://nfc.blvck.com'}/profile/${uniqueUrl}`}><button className="previewButton">PREVIEW</button></a>
      <div style={{ width: '100%', height: '100%' }} className="profile-page">
      <img style={{ height: '164px', objectFit: 'cover' }} src={
                        typeof bannerImage === 'object'
                            ? URL.createObjectURL(
                                  bannerImage
                              )
                            : (bannerImage ? bannerImage : 'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1669595007/profile.f0279413dda8e84f728a_sdqvwv.webp')
                    } alt="BLVCK" className="profile-page-banner" />
      <div style={{ gap: '10px' }} className="profile-contents">
        <img style={{ width: '98px', height: '98px', borderRadius: '100%', objectFit: 'cover' }} src={
                        typeof profileImage === 'object'
                            ? URL.createObjectURL(
                                  profileImage
                              )
                            : (profileImage ? profileImage : up)
                    } alt="BLVCK" className="profile-page-dp" />
        <div style={{ fontSize: '24px', lineHeight: '30px' }} className="profile-title">{name ? name : 'Name'}</div>
        <div className="profile-subtitle">{title ? title : 'Title'}</div>
        <div style={{ padding: '28px', minWidth: '285px', fontSize: '13px', marginTop: '15px' }} className="profile-button-main">{bio ? bio : 'Bio'}</div>
        <div style={{ flexDirection: 'column-reverse', marginTop: '15px', gap: '15px' }} className="mobile-reverse"><div style={{ marginTop: '8px' }} className="profile-button-flex">
          {mail ? <a target="_blank" href={`mailto:${mail}`}><img src={bt1} alt="Email" className="profile-button-secondary" /></a> : <img src={bt1} alt="Email" className="profile-button-secondary" />}
          {number ? <a target="_blank" href={`tel:${number}`}><img src={bt2} alt="Call" className="profile-button-secondary" /></a> : <img src={bt2} alt="Call" className="profile-button-secondary" />}
        </div>
        <div  style={{ marginTop: '0', gap: '24px', flexWrap: 'wrap', maxWidth: '250px', justifyContent: 'center' }} className="profile-socials-flex">
          {instaurl && <a target='_blank' href={`https://instagram.com/${instaurl}`}>
          <img src={Insta} alt="Insta" className="profile-social" />
          </a>}
          {facebookurl && <a style={{ width: '30px' }} target='_blank' href={facebookurl}>
          <img src={Facebook} alt="Insta" className="profile-social" />
          </a>}
          {linkedinurl && <a style={{ width: '30px' }} target='_blank' href={linkedinurl}>
          <img src={LinkedIn} alt="Insta" className="profile-social" />
          </a>}
          {twitterurl && <a target='_blank' href={`https://twitter.com/${twitterurl}`}>
          <img style={{ width: '44px' }} src={Twitter} alt="Twitter" className="profile-social" />
          </a>}
          {WhatsApp && <a target='_blank' href={`https://wa.me/${WhatsApp}`}>
          <img src={WhatsAppImg} alt="WhatsApp" className="profile-social" />
          </a>}
          {openSeaurl && <a target='_blank' href={`https://opensea.io/${openSeaurl}`}>
          <img style={{ width: '35px' }} src={OpenSea} alt="Opensea" className="profile-social" />
          </a>}
          {discordurl && <a target='_blank' href={discordurl}>
          <img style={{ width: '38px' }} src={Discord} alt="Discord" className="profile-social" />
          </a>}
        </div>
        </div>
      </div>
    </div>
      </div>
    </div>
  );
};

export default NFC;
