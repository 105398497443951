import React, { useState, useEffect } from "react";
import "./ressetpass.css";
import axios from "axios";
const ResetPassPage = () => {
  const [error, seterror] = useState("");
  const [success, setsuccess] = useState("");
  const [resetToken, setresetToken] = useState("");
  const [resetEmail, setresetEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disabled, setdisabled] = useState(false);

  useEffect(() => {
    if (window.location.href.split('/')[4] && window.location.href.split('/')[5]) {
      setresetToken(window.location.href.split('/')[4])
      setresetEmail(window.location.href.split('/')[5])
    }
  }, [])

  const handelClick = async (e) => {
    if (disabled) {
        return;
      }

    e.preventDefault();
    seterror('')
    setsuccess('')
    setdisabled(true)

    if (password !== confirmPassword) {
      seterror("Passwords don't match");
      setdisabled(false)
      return;
    }

    if (password.length < 12 || password.length > 32) {
      seterror("Password should be 12 to 32 characters long");
      setdisabled(false)
      return;
    }

    var errorMessage
    function passwordValid(str) {
      var number = /[0-9]/;
      if (!number.test(str)) {
        errorMessage = 'Password should contain 1 number'
        return false
      }

      var special = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (!special.test(str)) {
        errorMessage = 'Password should contain 1 special character'
        return false
      }

      var small = /[a-z]/;
      if (!small.test(str)) {
        errorMessage = 'Password should contain 1 lowercase letter'
        return false
      }

      var capital = /[A-Z]/;
      if (!capital.test(str)) {
        errorMessage = 'Password should contain 1 uppercase letter'
        return false
      }

      return true
    }

    if (!passwordValid(password)) {
      seterror(errorMessage);
      setdisabled(false)
      return;
    }
    
    PostData()
  };

  const PostData = async () => {
    const userResponse = {
      password: password,
      email: resetEmail,
      token: resetToken
    };
    let response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/blvck-nfc/reset-password`,
      userResponse
    );
    const { data } = response;
    if(data.msg.includes('successfully updated')){
      setsuccess(data.msg);
      setdisabled(false)
      window.open(`${process.env.REACT_APP_FRONTEND_URL}/login`, '_blank')
      return;
    } else {
        seterror(data.msg)
        setdisabled(false)
        return;
    }
  };
  return (
    <div className="rp-page">
      <div className="rp-page-box" onClick={e => e.stopPropagation()}>
        <h1 className="page-title pt">Reset Password</h1>
        <div style={{ fontSize: '14px', marginTop: '80px' }} className="page-subtitle ps">Enter your new password</div>
        <div style={{ position: 'relative' }}>
        <input
            style={{ marginTop: '30px' }}
          type="password"
          placeholder="Password"
          className="page-input pi"
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirm Password"
          className="page-input pi2"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {error && <p style={{ 
    color: 'rgb(255 0 0)',
    fontFamily: "Montserrat, sans-serif",
    fontSize: '14px',
    alignSelf: 'flex-start',
    position: 'absolute',
    bottom: '-30px',
 }}>{error}</p>}
 {success && <p className="successMobile" style={{ 
    color: 'rgb(0 255 0)',
    fontFamily: "Montserrat, sans-serif",
    fontSize: '14px',
    position: 'absolute',
    bottom: '-30px',
 }}>{success}</p>}
        </div>
        <div style={disabled ? { background: 'grey', cursor: 'not-allowed' } : {}} className="button bt" onClick={(e) => handelClick(e)}>
          Reset Password
        </div>
      </div>
    </div>
  );
};

export default ResetPassPage;
