import React, { useState } from "react";
import "./ressetpass.css";
import axios from "axios";
const ResetPass = ({close}) => {
  const [email, setEmail] = useState("");
  const [error, seterror] = useState("");
  const [success, setsuccess] = useState("");
  const [disabled, setdisabled] = useState(false);

  const handelClick = async (e) => {
    if (disabled) {
      return;
    }

    e.preventDefault();
    seterror('')
    setsuccess('')
    setdisabled(true)
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!email.match(validRegex)) {
      seterror("Please enter a valid Email");
      setdisabled(false)
      return;
    } else {
      PostData();
    }
  };
  const PostData = async () => {
    const userResponse = {
      email: email,
    };
    let response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/blvck-nfc/forgot-password`,
      userResponse
    ).catch((error) => {
      console.log(error.response.data.msg)
      seterror(error.response.data.msg)
      setdisabled(false)
      // navigate('/profile')
    })
    const { data } = response;
    if(data.msg.includes('Email have been sent to your email address')){
      setsuccess(data.msg);
      setdisabled(false)
      return;
    }
  };
  return (
    <div className="rp-page" onClick={()=>{
      close(false)
      setEmail('')
      }}>
      <div className="rp-page-box" onClick={e => e.stopPropagation()}>
        <h1 className="page-title pt">Forgot Password</h1>
        <div style={{ position: 'relative' }}>
          <div style={{ fontSize: '14px', marginTop: '110px' }} className="page-subtitle ps">Enter your email</div>
          <input
            type="email"
            style={{ marginTop: '30px', width: '100%' }}
            placeholder="Email"
            className="page-input pitu"
            onChange={(e) => setEmail(e.target.value)}
          />
          {error && <p style={{ 
    color: 'rgb(255 0 0)',
    fontFamily: "Montserrat, sans-serif",
    fontSize: '14px',
    alignSelf: 'flex-start',
    position: 'absolute',
    bottom: '-30px',
 }}>{error}</p>}
 {success && <p className="successMobile" style={{ 
    color: 'rgb(0 255 0)',
    fontFamily: "Montserrat, sans-serif",
    fontSize: '14px',
    position: 'absolute',
    bottom: '-30px',
 }}>{success}</p>}
        </div>
        <div style={disabled ? { background: 'grey', cursor: 'not-allowed' } : {}} className="button bt mt100" onClick={(e) => handelClick(e)}>
          Forgot Password
        </div>
      </div>
    </div>
  );
};

export default ResetPass;
