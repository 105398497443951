import React, { useState, useEffect } from "react";
import "./SignUp.css";
import banner from "../../images/banner.png";
import axios from "axios"
import { Link } from "react-router-dom";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, seterror] = useState("");
  const [error2, seterror2] = useState("");
  const [success, setsuccess] = useState("");
  const [name, setName] = useState("");
  const [disabled, setdisabled] = useState(false);

  const handelClick = async (e) => {
    if (disabled) {
      return;
    }

    e.preventDefault();
    seterror("")
    setsuccess("")
    seterror2("")
    setdisabled(true)
    if (!email || !password || !confirmPassword) {
      seterror("Please fill out all the fields")
      setdisabled(false)
      return
    }

    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!email.match(validRegex)) {
      seterror("Please enter a valid Email");
      setdisabled(false)
      return;
    }

    if (password !== confirmPassword) {
      seterror("Passwords don't match");
      setdisabled(false)
      return;
    }

    if (password.length < 12 || password.length > 32) {
      seterror("Password should be 12 to 32 characters long");
      setdisabled(false)
      return;
    }

    var errorMessage
    function passwordValid(str) {
      var number = /[0-9]/;
      if (!number.test(str)) {
        errorMessage = 'Password should contain 1 number'
        return false
      }

      var special = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      if (!special.test(str)) {
        errorMessage = 'Password should contain 1 special character'
        return false
      }

      var small = /[a-z]/;
      if (!small.test(str)) {
        errorMessage = 'Password should contain 1 lowercase letter'
        return false
      }

      var capital = /[A-Z]/;
      if (!capital.test(str)) {
        errorMessage = 'Password should contain 1 uppercase letter'
        return false
      }

      return true
    }

    if (!passwordValid(password)) {
      seterror(errorMessage);
      setdisabled(false)
      return;
    }
    
    PostData();
  };

  const PostData = async() => {
    const userResponse = {
      username: name,
      email: email,
      password: password,
    }
    let response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/blvck-nfc/register`,userResponse)
    const {data} = response;
    if(data.msg == 'Email already in use'){
      seterror(data.msg);
      setdisabled(false)
      return;
    }
    if(data.msg == 'Username already exists'){
      seterror2(data.msg);
      setdisabled(false)
      return;
    }
    if(data.msg.includes('Sign Up confirm')){
      setsuccess(data.msg);
      setdisabled(false)
      return;
    }
    if(data.status == 201){
      seterror(data.msg);
      setdisabled(false)
      return;
    }
  };
  return (
    <div style={{     padding: '60px 120px',
      height: '100vh',
      background: 'black',}} className="page">
      <div style={{ gap: '25px', height: '100%' }} className="page-content">
        <h1 className="page-title log">sign up</h1>
        <div style={{ position: 'relative', marginTop: '10px' }} className="page-inputs">
        <div style={{ fontSize: '14px', alignSelf: 'flex-start', marginTop: '10px' }} className="page-subtitle signupheading">
          Please enter your details to receive your NFC card.
        </div>
          <input
            type="email"
            placeholder="Email"
            className="page-input"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            className="page-input"
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            type="password"
            placeholder="Confirm Password"
            className="page-input"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {error && <p style={{ 
    color: 'rgb(255 0 0)',
    fontFamily: 'system-ui, sans-serif',
    fontSize: '14px',
    alignSelf: 'flex-start',
    position: 'absolute',
    bottom: '-30px',
 }}>{error}</p>}
 {success.includes('Sign Up confirm') ? <p className="successSignup" style={{ 
    color: 'rgb(0 255 0)',
    fontFamily: 'system-ui, sans-serif',
    fontSize: '14px',
    position: 'absolute',
    bottom: '-50px',
    lineHeight: '20px'
 }}>{success}</p> : <p className="successSignup" style={{ 
    color: 'rgb(0 255 0)',
    fontFamily: 'system-ui, sans-serif',
    fontSize: '14px',
    position: 'absolute',
    bottom: '-30px',
 }}>{success}</p>}
 {error2 && <p style={{ 
    color: 'rgb(255 0 0)',
    fontFamily: 'system-ui, sans-serif',
    fontSize: '14px',
    alignSelf: 'flex-start',
    position: 'absolute',
    bottom: '-30px',
    width: '100%'
 }}>{error2}</p>}
        </div>
           
        <div style={disabled ? (success.includes('Sign Up confirm') ? {background: 'grey', cursor: 'not-allowed', marginTop: '40px'} : { background: 'grey', cursor: 'not-allowed' }) : (success.includes('Sign Up confirm') ? {marginTop: '50px'} : {})}  className="button marginMobile" onClick={(e) => handelClick(e)}>
          sign up
        </div>
        <Link
            to="/login"
            className="page-subtitle marginminus"
            style={{ marginTop: "-10px", cursor: "pointer" }}
          >
            Already have a Blvck NFC card?
          </Link>
      </div>
      <div style={{ height: '100%' }} className="page-img">
        <img style={{ objectFit: 'cover' }} src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1669590153/banner.63a49f8455afd77efae3_jtqwdr.webp'} className="page-img-img dekstop-img-signup" alt="BLVCK" />
        <img style={{ objectFit: 'cover', display: 'none' }} src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1669730977/01_Sign_Up_1_3x_lzd4lw.webp'} className="page-img-img mobile-img-signup" alt="BLVCK" />
        <div className="mob-signup">
          <h1 className="page-title">sign up</h1>
          {/* <div className="page-subtitle">
            Please enter your details to receive your NFC card.
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
