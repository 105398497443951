import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useState, useEffect } from "react";
import NFC from "./Components/NFC/NFC";
import Profile from "./Components/Profile/Profile";
import ResetPassPage from "./Components/ResetPassword/resetPassPage";
import SignIn from "./Components/SignIn/SignIn";
import SignUp from "./Components/SignUp/SignUp";

function App() {
  try {
    JSON.parse(localStorage.getItem('userInfo'))
  } catch {
    localStorage.clear()
  }
  const [isUser, setisUser] = useState((localStorage.getItem('userInfo') && localStorage.getItem('isUser') && localStorage.getItem('accessToken')) ? localStorage.getItem('isUser') : false);
  const [userInfo, setuserInfo] = useState((localStorage.getItem('userInfo') && localStorage.getItem('isUser') && localStorage.getItem('accessToken')) ? JSON.parse(localStorage.getItem('userInfo')) : {});
  const [accessToken, setaccessToken] = useState((localStorage.getItem('userInfo') && localStorage.getItem('isUser') && localStorage.getItem('accessToken')) ? localStorage.getItem('accessToken') : '');

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/login" element={!isUser ? (
                                        <SignIn setisUser={setisUser} setuserInfo={setuserInfo} setaccessToken={setaccessToken} />
                                    ) : (
                                        <Navigate to='/' />
                                    )} />
          <Route exact path="/login/:token/:email" element={!isUser ? (
                                        <SignIn setisUser={setisUser} setuserInfo={setuserInfo} setaccessToken={setaccessToken} />
                                    ) : (
                                        <Navigate to='/' />
                                    )} />
          <Route exact path="/signup" element={!isUser ? (
                                        <SignUp />
                                    ) : (
                                        <Navigate to='/' />
                                    )} />
          <Route exact path="/profile/:profileId" element={<Profile />} />
          <Route exact path="/" element={isUser ? (
                                        <NFC userInfo={userInfo} accessToken={accessToken} setuserInfo={setuserInfo} />
                                    ) : (
                                        <Navigate to='/login' />
                                    )} />
          <Route exact path="/reset-password/:token/:email" element={!isUser ? (
                                        <ResetPassPage />
                                    ) : (
                                        <Navigate to='/' />
                                    )} />
          <Route exact path="/*" element={<Navigate to='/' />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
