import React,{useState, useEffect} from "react";
import "./profile.css";
import profile from "../../images/profile.png";
import dp from "../../images/dp.png";
import bt1 from "../../images/bt1.png";
import bt2 from "../../images/bt2.png";
import Insta from "../../images/insta.png";
import Twitter from "../../images/twitter.png";
import Facebook from "../../images/facebook.svg";
import LinkedIn from "../../images/linkedIn.svg";
import WhatsApp from "../../images/whatsapp.png";
import OpenSea from "../../images/opensea.png";
import Discord from "../../images/discord.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [user, setuser] = useState();
  const navigate = useNavigate()

  useEffect(() => {
    if (window.location.href.split('/')[4]) {
      axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/blvck-nfc/getUserByUniqueUrl/${window.location.href.split('/')[4]}`
      ).then((res) => {
        if (res.data.msg) {
          navigate('/')
        } else {
          setuser(res.data.user)
        }
      }).catch((error) => {
        console.log(error.response.data.msg)
      })
    }
  }, [])

  return (
    <div className="profile-page">
      <img style={{ height: '264px', objectFit: 'cover' }} src={user?.bannerImage} alt="BLVCK" className="profile-page-banner" />
      <div className="profile-contents">
        <img src={user?.profileImage} alt="BLVCK" className="profile-page-dp" />
        <div className="profile-title">{user?.name}</div>
        <div style={{ fontSize: '13px' }} className="profile-subtitle">{user?.title}</div>
        <div className="profile-button-main">{user?.bio}</div>
        <div className="mobile-reverse"><div className="profile-button-flex">
          <a target="_blank" href={`mailto:${user?.mail}`}>
          <img src={bt1} alt="Email" className="profile-button-secondary" /></a>
          <a target="_blank" href={`tel:${user?.number}`}>
          <img src={bt2} alt="Call" className="profile-button-secondary" /></a>
        </div>
        <div className="profile-socials-flex">
        {user?.instagramUrl && <a target="_blank" href={user?.instagramUrl}>
          <img src={Insta} alt="Insta" className="profile-social" /></a>}
          {user?.twitterUrl && <a target="_blank" href={user?.twitterUrl}>
          <img style={{ width: '44px' }} src={Twitter} alt="Twitter" className="profile-social" /></a>}
          {user?.facebookUrl && <a target="_blank" href={user?.facebookUrl}>
          <img style={{ width: '36px' }} src={Facebook} alt="Facebook" className="profile-social" /></a>}
          {user?.linkedinUrl && <a target="_blank" href={user?.linkedinUrl}>
          <img style={{ width: '36px' }} src={LinkedIn} alt="LinkedIn" className="profile-social" /></a>}
          {user?.whatsapp && <a target="_blank" href={`https://wa.me/${user?.whatsapp}`}>
          <img src={WhatsApp} alt="WhatsApp" className="profile-social" /></a>}
          {user?.openseaUrl && <a target="_blank" href={user?.openseaUrl}>
          <img style={{ width: '35px' }} src={OpenSea} alt="Opensea" className="profile-social" /></a>}
          {user?.discordUrl && <a target="_blank" href={user?.discordUrl}>
          <img style={{ width: '38px' }} src={Discord} alt="Discord" className="profile-social" /></a>}
        </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
