import React, { useState, useEffect } from "react";
import banner2 from "../../images/banner2.png";
import logo from "../../images/logo.png";
import "../SignUp/SignUp.css";
import { Link } from "react-router-dom";
import axios from "axios";
import ResetPass from "../ResetPassword/ResetPass";
import { useNavigate } from "react-router-dom";

const SignIn = (props) => {
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState("");
  const [error, seterror] = useState("");
  const [success, setsuccess] = useState("");
  const [password, setPassword] = useState("");
  const [disabled, setdisabled] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    if (window.location.href.split('/')[4] && window.location.href.split('/')[5]) {
      axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/blvck-nfc/verify-email`,
        {
          token: window.location.href.split('/')[4],
          email: window.location.href.split('/')[5]
        }
      ).then((res) => {
        setsuccess(res.data.msg)
      }).catch((error) => {
        console.log(error.response.data.msg)
        seterror(error.response.data.msg)
      })
    }
  }, [])

  const handelClick = async (e) => {
    if (disabled) {
      return;
    }

    seterror('')
    setsuccess('')
    setdisabled(true)
    e.preventDefault();
    if (!email || !password) {
      seterror("Please fill out all the fields")
      setdisabled(false)
      return
    }

    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!email.match(validRegex)) {
      seterror("Please enter a valid Email");
      setdisabled(false)
      return;
    } else {
      PostData();
    }
  };
  const PostData = async () => {
    const userData = {
      email: email,
      password: password,
    };
    let response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/blvck-nfc/login`,
      userData
    ).then((res) => {
      props.setisUser(true)
      props.setuserInfo(res.data.userInfo)
      props.setaccessToken(res.data.accessToken)
      localStorage.setItem('isUser', true)
      localStorage.setItem('userInfo', JSON.stringify(res.data.userInfo))
      localStorage.setItem('accessToken', res.data.accessToken)
      setdisabled(false)
    }).catch((error) => {
      console.log(error)
      seterror(error.response.data.msg)
      setdisabled(false)
      // navigate('/profile')
    })

    if (response) {
      const { data } = response;
      if (data.msg == `User doesn't exists`) {
        seterror(data.msg);
        setdisabled(false)
        return;
      }
      if (data.msg == "Wrong Password!!") {
        seterror(data.msg);
        setdisabled(false)
        return;
      }
      if (data.msg == "Please verify your email") {
        seterror(data.msg);
        setdisabled(false)
        return;
      }
      if (data.status == 201) {
        seterror(data.msg);
        localStorage.setItem("user_info", data.userInfo);
        setdisabled(false)
      }
    }
  };
  return (
    <>
      {modal ? <ResetPass close={setModal} /> : ""}
      <div style={window.innerHeight < 600 ? { padding: '60px 120px',
      height: '120vh',
      background: 'black' } : { padding: '60px 120px',
      height: '100vh',
      background: 'black' }} className="page">
        <div style={{ gap: '25px', height: '100%' }}  className="page-content signin-page">
          <img src={logo} alt="BLVCK PARIS" className="page-logo log" />
          <div className="page-subtitle--2 log">
            Sign in to manage your NFC profile.
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '25px', position: 'relative' }}>
          <input
            type="email"
            placeholder="Email"
            className="page-input"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            className="page-input"
            onChange={(e) => setPassword(e.target.value)}
          />
          {success && <p style={{ 
    color: 'rgb(0 255 0)',
    fontFamily: "Montserrat, sans-serif",
    fontSize: '14px',
    position: 'absolute',
    bottom: '8px',
 }}>{success}</p>}
          {error && <p style={{ 
    color: 'rgb(255 0 0)',
    fontFamily: "Montserrat, sans-serif",
    fontSize: '14px',
    alignSelf: 'flex-start',
    position: 'absolute',
    bottom: '8px',
 }}>{error}</p>}
 <div className="forgot-pass" onClick={() => setModal(true)}>
            forget password?
          </div>
          </div>
          <div
          style={disabled ? { background: 'grey', cursor: 'not-allowed', marginTop: '20px' } : { marginTop: '20px' }} 
            className="button"
            onClick={(e) => handelClick(e)}
          >
            login
          </div>
          <Link
            to="/signup"
            className="page-subtitle marginminus"
            style={{ marginTop: "-20px", cursor: "pointer" }}
          >
            Don’t have an account yet? Sign up!
          </Link>
        </div>
        <div style={{ height: '100%' }} className="page-img page-img22">
          <img style={{ objectFit: 'cover' }} src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1669590133/banner2.c87e6648fd2da0b416da_nj29uj.webp'} className="page-img-img dekstop-img-signup" alt="BLVCK" />
          <img style={{ objectFit: 'cover', display: 'none' }} src={'https://res.cloudinary.com/foxledgerstudio/image/upload/c_fill,g_xy_center,q_auto:best,w_1000/v1669742708/00_Login_1_3x_t2lbyz.webp'} className="page-img-img mobile-img-signup" alt="BLVCK" />
          <div className="mob-signin">
            <img src={logo} alt="BLVCK PARIS" className="page-logo" />
            <div className="page-subtitle--2 dekstop-img-signup">
              Sign in to manage your NFC profile.
            </div>
            <div style={{ display: 'none', position: 'absolute', bottom: 0, marginBottom: '65px' }} className="page-subtitle--2 mobile-img-signup mobile-img-signin">
              Sign in to manage your NFC profile.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
